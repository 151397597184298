angular
    .module('annexaApp')
    .component('annexaBoxTaskPaginated', {
        templateUrl: './components/tasks/annexa-box-task-paginated/annexa-box-task-paginated.html',
        controller: ['AnnexaObjectBoxFactory', 'AnnexaEntityFactory', 'TasksModals', 'ModalService', '$rootScope', 'GlobalDataFactory', 'Language', '$scope', '$filter', 'TaskFactory', 'AnnexaFormlyFactory', 'TableFilterFactory', 'HelperService', 'RegFactory', 'dialogs', 'RestService', 
        		function (AnnexaObjectBoxFactory, AnnexaEntityFactory, TasksModals, ModalService, $rootScope, GlobalDataFactory, Language, $scope, $filter, TaskFactory, AnnexaFormlyFactory, TableFilterFactory, HelperService, RegFactory, dialogs, RestService) {
            var vm = this;
            var languageColumn = Language.getActiveColumn();
            
			$scope.$on('annexaAdvancedSearchModalAdd', function (event, args) {
                if(args.id == 'modalASTasks_' + vm.box.origin) {
                    var id = undefined;
                    
                    if(args.selected && args.selected.length > 0){
                    	vm.box.search.searchedValue = { id: id, object: args.selected };
                        vm.box.search.completeAdd();
                    }

                    args.modal.close();
                }
            });

			this.$onInit = function() {
				if(vm.box && vm.box.tableDefinition){
					vm.box.permissions = AnnexaObjectBoxFactory.addNewViewPermissions(vm.box.permissions, 'create_task', 'create_task', vm.box.isEdit);
					vm.taskModule = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.tasks'").toArray();
	                if(!vm.box.boxTitle) {
	                    vm.box.boxTitle = 'global.literals.tasks';
		            }
	                if(vm.box.search) {
	                    vm.box.search.origin = 'tasks';
	                    vm.box.search.type = 'Task';
	                    vm.box.search.customFilter = '{ "taskNumber": "@VAL@", "description": "@VAL@"}';
	                    vm.box.search.additionalFilter = { finished: false }
	                    if (vm.box.origin) {
	                        switch (vm.box.origin) {
		                        case 'annexa-box-object-reg':
		                        	vm.box.search.additionalFilter.assignedRegisterEntry = true;
		                            break;
		                        case 'annexa-document':
		                        	vm.box.search.additionalFilter.assignedDoc = true;
		                            break;
		                        case 'annexa-dossier-transaction-tasks':
		                        	vm.box.search.additionalFilter.assignedCertificated = true;
		                            break;
		                        case 'task-task':
		                        case 'subtask-task':
		                        case 'duplicated-task':
		                        case 'third-task':
		                        	vm.box.search.additionalFilter.assignedTask = true;
		                            break;
		                    }
	                    	
	                    }
	                    
	                    vm.box.search.searchFunc = function(val) {
	                        var filter = { name: val.val };
	
	                        if(vm.box.search.customFilter) {
	                            var pareseableCustomFilter = vm.box.search.customFilter.replace('@VAL@', val.val);
	                            pareseableCustomFilter = pareseableCustomFilter.replace('@VAL@', val.val);
	                            filter = JSON.parse(pareseableCustomFilter);
	                        }
	
	                        var additionalFilter = vm.box.search.additionalFilter ? vm.box.search.additionalFilter : {};
	                        additionalFilter.loggedUser = $rootScope.LoggedUser.id;
	                        additionalFilter.paginated = true;
							additionalFilter.paginatedObject = vm.box.tableDefinition.objectIdToSelectType;
	                        additionalFilter.paginatedObjectId = vm.box.tableDefinition.objectIdToAdded;
	                        additionalFilter.langColumn = Language.getActiveColumn();
	
	                        return RestService.filterData(vm.box.search.origin, vm.box.search.type, filter, additionalFilter, 0, 10, '', 3, 'box')
	                            .then(function (data) {
	                                var response = [];
	                                _.forEach(data.data.content, function(value, key) {
	                                    response.push({
	                                        id: value.id,
	                                        object: value,
	                                        value: vm.box.search.processSearch(value)
	                                    });
	                                });
	                                response = new SortedArray(response, 'value').sort();
	                                return response;
	                            });
	
	                    }
	                    
	                    vm.box.search.processSearch = function (object) {
	                        var assigned = '';
	
	                        if(object.user) {
	                            assigned += object.user.name + ' ' + object.user.surename1 + (object.user.surename2 ? ' ' + object.user.surename2 : '') ;
	                        }
	                        if(object.profile) {
	                            assigned += object.profile[languageColumn];
	                        }
	
	                        return object.description + (assigned ? ' (' + assigned + ')' : '');
	                    }
	
	                    if(!vm.box.search.completeAdd) {
	                        vm.box.search.completeAdd = function() {
	                        	var newContent = [];
	                        	if(vm.box.search.searchedValue && vm.box.search.searchedValue.object && Array.isArray(vm.box.search.searchedValue.object)){
	                        		_.forEach(vm.box.search.searchedValue.object, function(obj){
	                        			newContent.push(obj);
	                        		});
	                        	}else{
	                        		newContent.push(vm.box.search.searchedValue.object);
	                        	}
	
                        		vm.box.search.searchedValue = { id: -1, value: '' };
                                if(vm.box.search.saveSearch) {
                                    vm.box.search.saveSearch(newContent);
                                }
	                        }
	                    }
	
	                    vm.box.search.advancedSearch = function() {
	                        var expiredStates = [
	                            { id: 'expired', name: $filter('translate')('global.literals.outOfTime') },
	                            { id: 'noExpired', name: $filter('translate')('global.literals.tramNoExpired') }
	                        ];
	
	                        var getSpecificStatuses = function (globalStatusList) {
	                            var specificStatuses = $linq(GlobalDataFactory.taskTypeStatuses).distinctBy("x => x." + languageColumn).orderBy("x => x." + languageColumn, linq.caseInsensitiveComparer).toArray();
	                            
	                            if (globalStatusList && globalStatusList.length > 0) {
	                            	specificStatuses = $linq(GlobalDataFactory.taskTypeStatuses).where(function(status){
	            	        			return $linq(globalStatusList).contains(status.globalStatus, function(x,y){
	            	        				if(x && x.id && y == x.id){
	            	        					return true;
	            	        				}else{
	            	        					return false;
	            	        				}
	            	        			});
	                        		}).distinctBy("x => x." + languageColumn).orderBy("x => x." + languageColumn, linq.caseInsensitiveComparer).toArray();
	                            }
	
	                            return HelperService.addAllSelect(angular.copy(specificStatuses), languageColumn, languageColumn);
	                        }
	
	                        var filterAdvancedSearchModal = [
	                            { id: 'taskNumber', type: 'text', order: 0, label: 'global.literals.code', callAux: true},
	                            { id: 'description', type: 'text', order: 1, label: 'global.literals.task_name', callAux: true },
	                            { id: 'type', type: 'select', order: 2, label: 'global.literals.taskType', dataType: 'LOCAL', data: GlobalDataFactory.taskTypes, addAll: true, nameProperty: languageColumn, callAux: true },
	                            //Altres criteris
	                            { id: 'createdUser', type: 'text', order: 0, label: 'global.literals.started_for', callAux: true, userChoosen: true },
	                            { id: 'user', type: 'text', order: 1, label: 'global.literals.assigned_to', callAux: true, userChoosen: true },
	                            { id: 'startDates', type: 'dateRange', order: 2, label: 'global.literals.startDate', callAux: true, userChoosen: true },
	                            { id: 'endDates', type: 'dateRange', order: 3, label: 'global.literals.endDate', callAux: true, userChoosen: true },
	                            { id: 'expired', type: 'select', order: 4, label: 'global.literals.expiredSate', dataType: 'LOCAL', data: expiredStates, addAll: true, nameProperty: 'name', callAux: true, userChoosen: true },
	                            { id: 'status', type: 'select-multiple', order: 5, label: 'global.literals.globalStatus', dataType: 'LOCAL', data: TaskFactory.taskStatuses, addAll: true, nameProperty: 'name', callAux: true, userChoosen: true },
	                            { id: 'specificStatus', type: 'select-multiple', order: 6, label: 'global.literals.status', dataType: 'LOCAL', data: getSpecificStatuses(), addAll: true, nameProperty: languageColumn, callAux: true, userChoosen: true },
	                            { id: 'thirdName', type: 'text', order: 7, label: 'global.thirds.literals.third', callAux: true, userChoosen: true },
	                            { id: 'address', type: 'text', order: 8, label: 'global.territory.list.address', callAux: true, userChoosen: true },
	                            { id: 'meta', type: 'text', order: 9, label: 'global.literals.meta', callAux: true, userChoosen: true }
	                        ];
	                        
	                        //Definim una funció pròpia perquè ens interesa carregar el desplegable dels estats específics de forma dinàmica
	                        var getFilterCallAux = function(){
	                            var filterCallAux = TableFilterFactory.getFilterCallAux(filterAdvancedSearchModal);
	                            filterCallAux.loggedUser = $rootScope.LoggedUser.id;
	                            filterCallAux.langColumn = languageColumn;
	                            
	                            filterCallAux.paginated = true;
	                        	filterCallAux.assignedTask = true;
	                            filterCallAux.paginatedObject = vm.box.tableDefinition.objectIdToSelectType;
								filterCallAux.paginatedObjectId = vm.box.tableDefinition.objectIdToAdded;
	                            if (advancedSearchModal) {
	                        		var filterSpecificStatusAux = $linq(advancedSearchModal.filter).singleOrDefault(undefined, "x => x.id == 'specificStatus'");
	                   				filterSpecificStatusAux.data = getSpecificStatuses(filterCallAux.status);
	
	                   				if(filterCallAux.specificStatus && filterCallAux.specificStatus.length > 0){
	                   					var specificListFilter = [];
	                   	                _.forEach(filterCallAux.specificStatus, function(value, index) {
	                   	   					if($linq(filterSpecificStatusAux.data).contains(value, function(x,y){
	                   	   						if(x && x.id && y.id == x.id) { return true; } else { return false; } 
	                   	   					})) {
	                   	   						specificListFilter.push({id: value[languageColumn]});
	                   	   					}
	                   	                });
	
	                   	                //Actualitzem el model amb els valors carregats al llistat specificListFilter
	                   	                filterSpecificStatusAux.model = $linq(filterSpecificStatusAux.model).where(function(specificStatus){
	                	        			return $linq(specificListFilter).contains(specificStatus[languageColumn], function(x,y){
	                	        				if(x && x.id && y == x.id) { return true; } else { return false; }
	                	        			});
	                   	                }).toArray();
	                   	                
	                   	                filterCallAux.specificStatus = specificListFilter;
	                        		} else {
	                    				filterSpecificStatusAux.model = undefined;
	                        		}
	                            }
	
	                        	return filterCallAux;
	                        };
	                        
	                        var advancedSearchModal = {
	                            title: 'global.literals.advanced_search_tasks',
	                            size: 'modal-lg',
	                            filter: filterAdvancedSearchModal,
	                            tableDefinition: {
	                                id: 'tableAdvancedSearchTasks',
	                                origin: 'tasks',
	                                objectType: 'Task',
	                                sortName: 'taskNumber',
	                                sort: [[1, 'asc']],
	                                filterCallAux: getFilterCallAux(),
	                                filterCallAuxFunc: getFilterCallAux,
	                                columns: [
	                                    { id: 'taskNumber', title: $filter('translate')('global.literals.code') },
	                                    { id: 'description', title: $filter('translate')('global.literals.task_name') },
	                                    { id: 'type.' + languageColumn, title: $filter('translate')('global.literals.taskType') }
	                                ],
	                                noLoading: true,
	                                skipFirstSearch: true,
									callAuxToAdd: {paginated:true, paginatedObject:vm.box.tableDefinition.objectIdToSelectType, paginatedObjectId:vm.box.tableDefinition.objectIdToAdded}
	                            },
	                            multiple: true
	                        }
	                        
	                        AnnexaFormlyFactory.showAnnexaAdvancedSearchModal('modalASTasks_' + vm.box.origin, advancedSearchModal);
	                    }
	
	                }
					if(vm.box.new) {
	                    vm.box.new.newFunc = function() {
	                        var proccesNew = function(item, form) {
	                            vm.box.search.searchedValue = { id: item.id, object: JSOG.decode(item) }
	                            $rootScope.$broadcast('annexaObjectBoxSearchAdd');
	                            form.close();
	                        }
	
	                        var modal = angular.copy(TasksModals.taskNew);
	                        var entity = new AnnexaEntity(new Date().getTime() + '', 'modal', AnnexaEntityFactory.getTaskBoxNewForm(modal, proccesNew), AnnexaEntityFactory.taskBoxes());
	
	                        if(vm.box.entryType && vm.box.isEdit){
	                            if(vm.box.entryType == "INPUT" ){
	                                entity.boxes[2].boxDefinition.content.push(RegFactory.regEntry);
	                            }else if(vm.box.entryType == "OUTPUT"){
	                                entity.boxes[3].boxDefinition.content.push(RegFactory.regEntry);
	                            }
	                        }
							if(vm.box.config && vm.box.config.isTramBox){
								entity.boxes[1].boxDefinition.content.push(vm.box.config.isTramBox);
	                        }
							if(vm.box.config && vm.box.config.third){
								entity.boxes[5].boxDefinition.content.push({third:vm.box.config.third});
	                        }
	                        modal.annexaFormly = entity.form;
	                        modal.boxes = entity.boxes;
	                        modal.submitModal = modal.annexaFormly.onSubmit;
	                        modal.alerts = [];
	                        entity.modal = modal;
	
	                        ModalService.showModal({
	                            template: '<annexa-entity id="' + entity.id + '" entity-definition="modalObj" class="modal black-overlay" data-backdrop="false"/>',
	                            controller: 'AnnexaFormlyModalController',
	                            modalObj: entity
	                        }).then(function(modalThen) {
	                            modal.modal = modalThen;
	                            modal.close = modalThen.element.scope().close;
	                            modal.annexaFormly.close = modalThen.element.scope().close;
	                            modalThen.element.modal();
	                            angular.element(".modal-title").focus();
	                        });
	                    }
	                }

				}
			}
        }],
        bindings: {
            box: '='
        }
    })
   